<template lang="pug">
Popup

    template(slot="title")

      template(
        v-if="isEdit"
      ) {{ $t('Tax.newFormHeaderTitleEdit') }}

      template(
        v-else
      ) {{ $t('Tax.newFormHeaderTitle') }}

    template(slot="content")

      form.Form(
        id  ="form-tax",
        @submit.prevent="onSubmit(isEdit)"
      )

        div.Form-item.required

          label.Form-item-label {{ $t('Tax.newFormFieldName') }}

          .control
            customTextInput(
              id="input-tax-form-name"
              ref="firstField"
              v-model="form.name"
              v-validate="'required|max:32|verify_name'"
              name="name"
              :data-vv-as="$t('Tax.newFormFieldName')"
              :error="veeErrors.has('name')"
            )

            showValidateError(
              v-show="veeErrors.has('name')"
              :errorName="veeErrors.first('name')"
            )

            Button(
              size          ="small",
              variant       ="icon loading",
              id            ="btn-tax-popup-loading-name",
              tabindex      ="-1",
              v-show="isPendingName"
            )
              Loading(theme="disable")

            Button(
              size          ="small",
              variant       ="icon check",
              id            ="btn-tax-popup-ok-name",
              tabindex      ="-1",
              v-show        ="isValidateName"
              iconName      ="icon-global-check",
              iconClass     ="icon-global-check",
              :justIcon     ="true"
            )

        div.Form-item.required

          label.Form-item-label {{ $t('Tax.newFormFieldRatio') }}

          .control

            customNumberInput(
              name="ratio"
              :error="veeErrors.has('ratio')"
              :data-vv-as="$t('Tax.newFormFieldRatio')"
              :isTypePercentage="true"
              placeholder=',00'
              v-validate="'required|decimal:2|between:0,100'"
              id="input-tax-form-tax-ratio"
              v-model="form.ratio"
            )

            showValidateError(
              v-show="veeErrors.has('ratio')"
              :errorName="veeErrors.first('ratio')"
            )

        div.Form-item

          label.Form-item-label {{ $t('Tax.newFormFieldcode') }}

          .control

            customTextInput(
              name="code"
              id="input-tax-form-tax-code"
              v-model="form.code"
              v-validate="`very_singularity:Tax/check/code/?Id=${this.$route.params.id || ''},Code`"
              :data-vv-as="$t('Tax.newFormFieldcode')"
              :error="veeErrors.has('code')"
            )

            showValidateError(
              v-show="veeErrors.has('code')"
              :errorName="veeErrors.first('code')"
            )

    template(slot="footer")
      div.add-other-items-checkbox-container(v-if="!isEdit")
        CustomCheckbox(
          id="checkbox-tax-save-and-new"
          :label="$t('Global.formSaveAndNewText', { form: $t('Tax.newForm_Name') })"
          v-model="saveAndNew"
        )

      Button(
        size      ="large",
        id        ="btn-tax-popup-submit",
        variant   ="full",
        primary,
        type      ="submit",
        form      ="form-tax",
        :disabled ="isLoading",
        :isLoading="isLoading"
      )
        span(v-show="!isLoading")
          template(v-if="isEdit") {{ $t('Tax.newFormFooterSubmitEdit') }}
          template(v-else) {{ $t('Tax.newFormFooterSubmit') }}

</template>

<script>
import { mapFields, Validator } from 'vee-validate'
import { mapActions } from 'vuex'
import { omit, getObjectValuesByKey, vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'TaxFormNew',

  props: {
    status: {
      type: String,
      default: 'new'
    }
  },

  data () {
    return {
      form: {
        id: null,
        name: null,
        ratio: null,
        code: null
      },

      saveAndNew: true
    }
  },

  created () {
    const self = this

    const verifyName = {
      getMessage (field, params, data) {
        return (data && data.message) || 'Something wrong'
      },
      validate (value) {
        return new Promise(resolve => {
          let params = {
            Name: value
          }
          if (self.isEdit) {
            params.Id = self.form.id
          }

          self.checkTaxName(params)
            .then(res => {
              if (res) {
                resolve({
                  valid: res.data.isAvailable
                })
              } else {
                resolve({
                  valid: false
                })
              }
            })
        })
      }
    }

    const verifyNameMessages = {
      tr: {
        messages: {
          verify_name: field => 'Bu vergi adı daha önce sisteme kaydedilmiş.'
        }
      }
    }

    Validator.extend('verify_name', verifyName)
    Validator.localize(verifyNameMessages)
  },

  mounted () {
    if (this.isEdit) this.init()
    this.formFocus()
  },

  computed: {
    ...mapFields({
      flagsName: 'name'
    }),

    isLoading () {
      return this.$wait.is(['Tax/updateTax', 'Tax/createTax', 'taxHeaderSubmit'])
    },

    isEdit () {
      return this.status === 'edit'
    },

    isPendingName () {
      return this.flagsName.pending
    },

    isValidateName () {
      if (this.flagsName.required) {
        return this.flagsName.valid && !this.isPendingName
      } else {
        return this.flagsName.valid && this.item.name !== '' && this.item.name !== null && !this.isPendingName
      }
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Tax', [
      'getTax',
      'createTax',
      'updateTax',
      'checkTaxName'
    ]),

    init () {
      if (!this.$route.params.tax) {
        let taxId = this.$route.params.id
        this.getTax(taxId)
          .then(res => {
            this.form = res.data.tax
          })
      } else {
        this.form = this.$route.params.tax
      }
    },

    close (withGetList = false) {
      if (withGetList) this.getList()
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    formFocus () {
      this.$refs.firstField.focusCustomInput()
    },

    onSubmit: vueWaitLoader(async function (isEdit) {
      await this.$validator.validateAll().then(async result => {
        if (!result) return
        if (isEdit) return this.submitEdit()
        return this.submitNew()
      })
    }, 'taxHeaderSubmit'),

    getList () {
      this.$emit('getList')
    },

    async submitNew () {
      let tax = omit(this.form, ['id'])
      await this.createTax(tax).then(async res => {
        if (res) {
          const message = this.$t('Global.notification_Created', { name: tax.name })
          this.notifyShow({ message })
          if (this.saveAndNew) {
            this.form = {
              id: null,
              name: null,
              ratio: null,
              code: null
            }
            this.$nextTick(() => {
              this.$validator.reset()
              this.formFocus()
            })
            this.getList()
          } else {
            this.close(true)
          }
        }
      })
    },

    async submitEdit () {
      const payload = getObjectValuesByKey(this.form, ['id', 'code', 'name', 'ratio'])
      await this.updateTax(payload).then(async res => {
        if (res) {
          const message = this.$t('Global.notification_Updated', { name: payload.name })
          this.notifyShow({ message })
          this.close(true)
        }
      })
    }
  }
}
</script>
