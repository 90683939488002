var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c(
        "template",
        { slot: "title" },
        [
          _vm.isEdit
            ? [_vm._v(_vm._s(_vm.$t("Tax.newFormHeaderTitleEdit")))]
            : [_vm._v(_vm._s(_vm.$t("Tax.newFormHeaderTitle")))],
        ],
        2
      ),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          {
            staticClass: "Form",
            attrs: { id: "form-tax" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmit(_vm.isEdit)
              },
            },
          },
          [
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Tax.newFormFieldName"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customTextInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|max:32|verify_name",
                        expression: "'required|max:32|verify_name'",
                      },
                    ],
                    ref: "firstField",
                    attrs: {
                      id: "input-tax-form-name",
                      name: "name",
                      "data-vv-as": _vm.$t("Tax.newFormFieldName"),
                      error: _vm.veeErrors.has("name"),
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("name"),
                        expression: "veeErrors.has('name')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("name") },
                  }),
                  _c(
                    "Button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isPendingName,
                          expression: "isPendingName",
                        },
                      ],
                      attrs: {
                        size: "small",
                        variant: "icon loading",
                        id: "btn-tax-popup-loading-name",
                        tabindex: "-1",
                      },
                    },
                    [_c("Loading", { attrs: { theme: "disable" } })],
                    1
                  ),
                  _c("Button", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isValidateName,
                        expression: "isValidateName",
                      },
                    ],
                    attrs: {
                      size: "small",
                      variant: "icon check",
                      id: "btn-tax-popup-ok-name",
                      tabindex: "-1",
                      iconName: "icon-global-check",
                      iconClass: "icon-global-check",
                      justIcon: true,
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Tax.newFormFieldRatio"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customNumberInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|decimal:2|between:0,100",
                        expression: "'required|decimal:2|between:0,100'",
                      },
                    ],
                    attrs: {
                      name: "ratio",
                      error: _vm.veeErrors.has("ratio"),
                      "data-vv-as": _vm.$t("Tax.newFormFieldRatio"),
                      isTypePercentage: true,
                      placeholder: ",00",
                      id: "input-tax-form-tax-ratio",
                    },
                    model: {
                      value: _vm.form.ratio,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "ratio", $$v)
                      },
                      expression: "form.ratio",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("ratio"),
                        expression: "veeErrors.has('ratio')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("ratio") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Tax.newFormFieldcode"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customTextInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: `very_singularity:Tax/check/code/?Id=${
                          this.$route.params.id || ""
                        },Code`,
                        expression:
                          "`very_singularity:Tax/check/code/?Id=${this.$route.params.id || ''},Code`",
                      },
                    ],
                    attrs: {
                      name: "code",
                      id: "input-tax-form-tax-code",
                      "data-vv-as": _vm.$t("Tax.newFormFieldcode"),
                      error: _vm.veeErrors.has("code"),
                    },
                    model: {
                      value: _vm.form.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "code", $$v)
                      },
                      expression: "form.code",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("code"),
                        expression: "veeErrors.has('code')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("code") },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          !_vm.isEdit
            ? _c(
                "div",
                { staticClass: "add-other-items-checkbox-container" },
                [
                  _c("CustomCheckbox", {
                    attrs: {
                      id: "checkbox-tax-save-and-new",
                      label: _vm.$t("Global.formSaveAndNewText", {
                        form: _vm.$t("Tax.newForm_Name"),
                      }),
                    },
                    model: {
                      value: _vm.saveAndNew,
                      callback: function ($$v) {
                        _vm.saveAndNew = $$v
                      },
                      expression: "saveAndNew",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "Button",
            {
              attrs: {
                size: "large",
                id: "btn-tax-popup-submit",
                variant: "full",
                primary: "",
                type: "submit",
                form: "form-tax",
                disabled: _vm.isLoading,
                isLoading: _vm.isLoading,
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLoading,
                      expression: "!isLoading",
                    },
                  ],
                },
                [
                  _vm.isEdit
                    ? [_vm._v(_vm._s(_vm.$t("Tax.newFormFooterSubmitEdit")))]
                    : [_vm._v(_vm._s(_vm.$t("Tax.newFormFooterSubmit")))],
                ],
                2
              ),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }