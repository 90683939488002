import Index from '../'
import Form from '../popup/form'

export default [
  {
    path: '/tax',
    name: 'Tax',
    component: Index,
    children: [
      {
        path: 'new',
        name: 'TaxNew',
        component: Form
      },
      {
        path: ':id/edit',
        name: 'TaxEdit',
        component: Form,
        props: {
          status: 'edit'
        }
      }
    ],
    meta: {
      main_menu: true,
      slug: 'tax',
      page: 'tax',
      category: 'Main_data',
      permissionKey: 'TaxesMenu'
    }
  }
]
