import BaseClass from '@/utils/baseclass'

export default class Taxes extends BaseClass {
  addItem(item) {
    let newItem = new Tax(item)
    this.list.push(newItem)
  }

  updateItem(item) {
    let findItem = this.findItem(item.id)
    if (findItem) {
      findItem.id = item.id
      findItem.name = item.name
      findItem.ratio = item.ratio
      findItem.code = item.code
    }
  }
}

class Tax {
  constructor(item) {
    this.id = item.id
    this.name = item.name
    this.ratio = item.ratio
    this.code = item.code
  }
}
