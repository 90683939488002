import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import Taxes from '../model'

export default {
  namespaced: true,
  state: {
    Taxes: new Taxes()
  },
  getters: {
    Taxes: state => state.Taxes.list,
    findTaxItem: state => id => state.Taxes.findItem(id),
    isTaxEmpty: state => state.Taxes.length === 0
  },
  actions: {
    async getTaxes({ commit, dispatch }) {
      const loadingName = 'Tax/getTaxes'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Tax/all?PageSize=999&PageNumber=1')
        },
        success: result => {
          commit('UPDATE_TAX_LIST', result.data.taxList)
          return result
        }
      })
      return results
    },
    async getTax({ state, commit, dispatch }, Id) {
      const loadingName = 'Tax/getTax'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Tax', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },

    async createTax({ commit, dispatch }, tax) {
      const loadingName = 'Tax/createTax'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('Tax', tax)
        },
        success: result => result
      })
      return results
    },
    async updateTax({ commit, dispatch }, tax) {
      const loadingName = 'Tax/updateTax'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.put('Tax', tax)
        },
        success: result => result
      })
      return results
    },

    async checkTaxName({ commit, dispatch }, params) {
      const loadingName = 'checkTaxName'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Tax/check/name', { params })
        },
        success: result => result
      })
      return results
    }
  },
  mutations: {
    RESET(state) {
      state.Taxes = new Taxes()
    },
    UPDATE_TAX_LIST(state, list) {
      state.Taxes.addItems(list)
    }
  }
}
